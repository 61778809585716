<template>
        <div class="form-list">
            <el-form ref="_ExcelForm" :model="dataSource">
                <el-form-item>
                    <el-col :span="2" class="form-title">
                        模板选择：
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <el-select v-model="TemplateID" placeholder="选择模板" class="select-import">
                                <el-option v-for="item in templateList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        点击<el-button type="text" @click="DownloadFile()"
                        style="text-decoration:underline;color:#1874CD;cursor:pointer">这里</el-button>下载导出模板
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                TemplateID: '',
                templateList: [],
                fileList: [],
                fileUrl:"omsapi/product/exportcustomtemplateproduct"
            };
        },
        props: {
            dataSource: {}
        },
        mounted() {
            this.loadTemplateListByBusinessType(this.dataSource.busType);
        },
        methods: {
            loadTemplateListByBusinessType(value) {
                this.$ajax.send("omsapi/order/get/orderimporttemplate/bybusinesstype", "get", { ImportExportType: 2, BusinessType: value }, (data) => {
                    this.templateList = data.Result;
                    if (data.Result && data.Result.length > 0) {
                        this.statusInfo = '';
                        this.selectedTemplate = this.templateList[0].value;
                    }
                });
            },
            DownloadFile: function () {
                if (this.TemplateID == null || this.TemplateID == '' || this.TemplateID == this.Utils.emptyGuid) {
                    this.Utils.messageBox("请选择模板。", "error");
                    return;
                }
                var url = "omsapi/order/orderimporttemplate/exporttemplate?templateid=" + this.TemplateID;
                this.Utils.export(url);
                // this.Utils.export("omsapi/common/downloadtemplate?uploadFileType=700&fileName=商品模板.xlsx");
            },
            submit(options) {
                var urlType;
                console.log("submit");
                if (this.TemplateID == "") {
                    //urlType = this.dataSource.url + "?queryParam=" + this.dataSource.queryParam;
                    return;
                }
                else
                {
                    urlType = this.fileUrl + "?queryParam=" + this.dataSource.queryParam + "&TemplateID=" + this.TemplateID
                }
                this.Utils.export(urlType);
                options.visible = false;
            },
        }

    }
</script>
<style scope>
    input[type="file"] {
        display: none;
    }

    .el-upload-list {
        width: 200px;
    }

    .el-select {
        width: 135px;
    }
</style>